<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getProductList"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <h2 id="exportProductTitle" class="col-md-10 col-sm-9 col-8 font-weight-normal">
              {{ $t('report.txtExportProduct') }}
            </h2>
            <div
              v-if="isExport"
              class="col-md-2 col-sm-3 col-4 text-right text-success"
            >
              <CButton
                id="exportProductExportButton"
                v-on:click="exportProductList()"
                block
                color="info"
                class="mb-1"
              >
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
            </CButton>
            </div>
          </div>
          <show-date></show-date>
          <CRow class="mt-4">
            <CCol lg="3" md="3" sm="5" col="6" class="mb-1">
              <select id="exportProductSkuTypeSelect" class="custom-select" v-model="skuType">
                <option id="exportProductSkuOptionAll" value="">{{ $t('showProductAll') }}</option>
                <option id="exportProductSkuOptionP" value="P" selected>{{ $t('productTypeP') }}</option>
                <option id="exportProductSkuOptionBOM" value="BOM">{{ $t('productTypeBOM') }}</option>
                <option id="exportProductSkuOptionSN" value="SN">{{ $t('productTypeSN') }}</option>
                <option id="exportProductSkuOptionSV" value="SV">{{ $t('productTypeSV') }}</option>
              </select>
            </CCol>
            <CCol lg="4" md="3" sm="5" col="6">
              <div style="margin-left: 2px; ">
                <multiselect
                  id="exportProductCategoryMultiselect"
                  v-model="selectedCategories"
                  :options="categorylist"
                  :multiple="true"
                  :search="true"
                  :showLabels="false"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :placeholder="$t('selectCategory')"
                  label="name"
                  track-by="name"
                  :group-select="false"
                  
                  >
                  <!-- <template slot="tag" style="height:35px;" slot-scope="{ option, remove }"><span class="custom__tag"><span></span></span></template> -->
                  <template
                    slot="selection"
                    slot-scope="{ values, isOpen }"
                  >
                    <span v-if="selectedCategories.length && !isOpen">
                      {{ selectedCategories.length }} {{ $t('category') }}
                    </span>
                  </template>
                </multiselect>
              </div>
            </CCol>
            <CCol lg="3" md="3" sm="3" col="6">
              <div style="margin-left: 2px;">
                <CInput
                  id="exportProductKeywordInput"
                  v-model.trim="keyword"
                  :placeholder="$t('keyword')"
                />
              </div>
            </CCol>
            <CCol lg="2" md="3" sm="3" col="6"
              v-if="searchLoadingButton === true"
            >
              <CButton id="exportProductSearchButton" v-on:click="getProductList()" block color="success">
                {{ $t('search') }}
              </CButton>
            </CCol>
            <CCol
              lg="2"
              md="2"
              sm="3"
              v-else-if="searchLoadingButton === false"
            >
              <CButton block color="success" disabled>
                <CSpinner color="white" size="sm" />
              </CButton>
            </CCol>
          </CRow>
          <hr class="mb-3 mt-0"/>
          <CRow>
            <CCol sm="12" lg="12">
              <!-- <sortdata v-on:getSort="setSort" :sort_value="sort_value" :sortdata ="sortdata" /> -->
              <!-- <hr /> -->
              <div style="overflow-x:auto;">
                <DataTable
                  id="exportProductTable"
                  :items="items"
                  :fields="fields"
                  :loading="loading"
                  hover
                  border
                  responsive
                  style="white-space: nowrap;"
                >
                </DataTable>
              </div>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getProductList">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import local from '@/services/local'
import Multiselect from 'vue-multiselect'
import permis from '@/util/permission'

export default {
  components: {
    Pagination,
    DataTable,
    Multiselect,
  },
  data() {
    return {
      data: [],
      dataCategory: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: 50,
        itemCount: null,
      },
      sort_value: {
        name: 'SKUName',
        category: 'category.name',
        onhandQtyMin: 'onhandQtyMin',
        onhandQtyMax: 'onhandQtyMax',
        PLUCode: 'ProductPLU.PLUCode',
        skuType: 'skuType',
      },
      sortdata: 'SKUName',
      loadingButton: true,
      keyword: '',
      skuType: '',
      searchLoadingButton: true,
      searchBy: 'SKUName',
      selectedCategories: [],
      
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        { key: 'index', label: this.$i18n.t('#'), _classes:'text-center text-dark font-weight-normal' },
        { key: 'skuCode', label: this.$i18n.t('SKUCode'), _classes:'text-dark font-weight-normal' }, //skucode (จำเป็น)
        { key: 'barcode', label: this.$i18n.t('barcode'), _classes:'text-dark font-weight-normal'}, //barcode
        { key: 'category', label: this.$i18n.t('category'), _classes:'text-dark font-weight-normal' }, //หมวดหมู่
        { key: 'name', label: this.$i18n.t('productName'), _classes:'text-dark font-weight-normal' }, //ชื่อสินค้า
        {
          key: 'price',
          label: this.$i18n.t('exceedUc'),
          _classes: 'text-right text-dark font-weight-normal',
        }, //ราคาขาย
        { key: 'stdCost', label: this.$i18n.t('cost'), _classes: 'text-right text-dark font-weight-normal' }, //ต้นทุน
        { key: 'unit', label: this.$i18n.t('unit'), _classes: 'text-left text-dark font-weight-normal' }, //ชื่อหน่วยบรรจุ
        { key: 'ratio', label: this.$i18n.t('Ratio'), _classes: 'text-right text-dark font-weight-normal' }, //ratio
        { key: 'vat', label: this.$i18n.t('VAT'), _classes: 'text-left text-dark font-weight-normal' }, //VAT
        { key: 'type', label: this.$i18n.t('Type'), _classes: 'text-left text-dark font-weight-normal' }, //Type
        {
          key: 'serial',
          label: this.$i18n.t('productSN'),
          _classes: 'text-left text-dark font-weight-normal',
        }, //serial number
      ]
    },
    items() {
      const data = this.data || []
      let detail = []
      
      const currentPage = (this.meta_data.current_page || 1) - 1
      const limit = this.meta_data.limit || 50

      for (let i = 0; i < data.length; i++) {
        const index = (currentPage * limit) + (i + 1)
        const item = data[i]
        
        
        detail.push({
          index: index,
          skuCode: item.skuCode || '',
          name: item.name || '',
          barcode: item.barcode || '',
          category: item.category || 'N/A',
          price: (item.price || 0).toFixed(2),
          stdCost: (item.stdCost || 0).toFixed(2),
          unit: item.unit || '',
          ratio: item.ratio || '1',
          vat: item.vat || '',
          type: item.type || '',
          serial: item.serial || '-',
        })
      }
      return detail
    },
    categorylist() {
      const data = this.dataCategory || []
      let detail = []
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        if (item.enabled === true) {
          detail.push({
            id: item.id,
            name: item.name,
            objectId: item.objectId,
          })
        }
      }
      return detail
    },
    sortAttr: {
      get() {
        let sortdata = this.sortdata
        if (sortdata == 'sortByNumMin' || sortdata == 'sortByNumMax') {
          sortdata = 'SKUPrice'
        }

        if (sortdata == 'onhandQtyMin' || sortdata == 'onhandQtyMax') {
          sortdata = 'ProductSKUStock.onhandQty'
        }
        return sortdata
      },
      set(newValue) {
        return newValue
      },
    },
    categoryIds() {
      let categoryIds = ''
      const data = this.selectedCategories
      for (let i = 0; i < data.length; i++) {
        const category = data[i]
        categoryIds = category.objectId + ',' + categoryIds
      }
      return categoryIds
    },
  },
  created() {
    this.getCategory()
    this.getProductList()
  },
  methods: {
    ...util,
    getCategory() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      let detail = []
      let params = {
        limit: 999
      }
      
      local
        .get('/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId, { params })
        .then((res) => {
          this.dataCategory = res.data.data
          let data = this.dataCategory
          for (let i = 0; i < data.length; i++) {
            detail.push({
              id: data[i].id,
              name: data[i].name,
              objectId: data[i].objectId,
            })
          }
        })
      this.dataCategory = detail
    },
    setSort(value) {
      this.sortdata = value
      this.getProductList()
    },
    getProductList(page = 1) {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      let category = this.categoryIds
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: this.meta_data.limit,
      }
      if (category != '') {
        Object.assign(params, { category: category })
      }
      //searchWord
      if (this.keyword != '') {
        Object.assign(params, { searchWord: this.keyword })
      }
      //type
      if (this.skuType != '') {
        Object.assign(params, { type: this.skuType })
      }
      const headers = {
        shopObjectId: shopObjectId,
      }

      this.loading = true
      axios({
        url: '/api/v1.0/' + uid + '/product/export/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          let data = res.data
          
          this.data = data.data
          
          const paginate = data.paginate || {}
          this.meta_data.items = paginate.items || 0
          this.meta_data.last_page = paginate.pageCount || 0
          this.meta_data.current_page = paginate.currentPage || 0
          this.meta_data.itemCount = paginate.itemCount || 0
          this.meta_data.limit = paginate.perPage || 0
          
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportProductList() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      let category = this.categoryIds
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }
      if (category != '') {
        Object.assign(params, { category: category })
      }
      //searchWord
      if (this.keyword != '') {
        Object.assign(params, { searchWord: this.keyword })
      }
      //type
      if (this.skuType != '') {
        Object.assign(params, { type: this.skuType })
      }
      const headers = {
        shopObjectId: shopObjectId,
      }

      report({
        url: '/api/v1.0/' + uid + '/product/export/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานส่งออกรายการสินค้า.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
<style src="../../assets/styles/multiselect.css"></style>
